.pageBestSum__container {
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 0 0;
}

.pageBestSum__container__input_data
{
    display: flex;
    width: 25%;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: start;
    align-items: center;
    justify-content: center;
}

.pageBestSum__container__input_data .title
{
    font-size: 36px;
    font-weight: 100;
    text-align: center;
    margin: 10px 0;
}

.pageBestSum__container__input_data input
{
    font-weight: 400;
    border-radius: 10px;
    border: #ccc;
    font-size: 26px;
    text-align: center;
}

.pageBestSum__container__input_data__wish_sum
{
    margin: 0 0 30px 0;
    width: 100%;
}

.pageBestSum__container__input_data__wish_sum__input_field
{
    width: 98%;
}

.pageBestSum__container__input_data__wish_sum__input_field input
{
    padding: 10px 0;
    width: 100%;
}

.pageBestSum__container__input_data__values 
{

}

.pageBestSum__container__input_data__values__inputs
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: start;
    align-items: center;
    justify-content: start;
}

.pageBestSum__container__input_data__values__inputs__input_field 
{
    width: 31%;
    padding: 10px 1%;
}

.pageBestSum__container__input_data__values__inputs__input_field .delete
{
}

.pageBestSum__container__input_data__values__inputs__input_field input 
{
    width: 100%;
    font-size: 14px;
    padding: 3px 0;
}

.pageBestSum__container__output_data
{
    display: flex;
    width: 40%;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: start;
    align-items: center;
}

.pageBestSum__container__output_data__result_sum
{
    font-size: 82px;
    font-weight: 800;
    position: relative;
}

.pageBestSum__container__output_data__result_sum__full_eq::before
{
    content: '\1F389';
    font-size: 36px;
    top: 30%;
    left: -51px;
    position: absolute;
}
.pageBestSum__container__output_data__result_sum__full_eq::after
{
    content: '\1F389';
    font-size: 36px;
    top: 30%;
    right: -57px;
    position: absolute;
}

a.up:after { content: " \2193"; }
a.down:after { content: " \2191"; }

.pageBestSum__container__output_data__result_values
{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: start;
    text-align: center;
    margin: 40px 0 0;
}

.pageBestSum__container__output_data__result_values__formula
{
    font-weight: 200;
    font-size: 18px;
    padding: 5px 0;
}

.hint
{
    padding: 3px 0;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    font-weight: 200;
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .pageBestSum__container {
        flex-direction: column;
        justify-content: start;
    }

    .pageBestSum__container__input_data {
        width: 60%;
    }

    .pageBestSum__container__output_data {
        width: 100%;
        padding: 30px 0 70px;
    }
}