
.pageCup__container {
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .pageCup__container__icon {
    padding: 0 20px;
  }
  .pageCup__container__icon img {
    width: 175px;
    text-shadow: 11px 0px #efefef;
    filter: drop-shadow(10px 2px 0px #ededed);
  }
  .pageCup__container__text {
    font-family: 'Roboto';
    font-size: 72px;
    color: #58595b;
    text-shadow: 10px 2px #ededed;
  }
  
  @media screen and (max-width: 1100px) {
    .pageCup__container__icon img {
      filter: drop-shadow(5px 2px 0px #ededed);
      width: 120px;
    }
    .pageCup__container__text {
      text-shadow: 5px 2px #ededed;
      font-size: 42px;
    }
  }
  
  @media screen and (max-width: 850px) {
    .pageCup__container {
        flex-direction: column;
        align-content: center;
        flex-wrap: wrap;
        text-align: center;
    }
    .pageCup__container__icon {
        padding: 20px 0px;
    }
    .pageCup__container__icon img {
      width: 150px;
    }
    .pageCup__container__text {
      font-size: 40px;
      padding: 0 20px;
    }
  }
  