.pageSymbols__container {
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 0 0;
}

.pageSymbols__container__textarea {
    display: flex;
    width: 40%;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;    
    min-width: 100px;
    min-height: 300px;
}

.pageSymbols__container__textarea textarea {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    resize: none;
    padding: 10px;
    font-size: 16px;
}

.pageSymbols__container__datas {
    display: flex;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;    
    margin-left: 50px;
}

.pageSymbols__container__datas .data {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.pageSymbols__container__datas .data_title {
    font-size: 52px;
    font-weight: 100;
}

.pageSymbols__container__datas .data_count {
    font-size: 82px;
    padding: 0 0 20px 0;
    font-weight: 800;
}

.pageSymbols__container__datas .data_count .gray {
    color: #ccc;
}

@media screen and (max-width: 1000px) {
    .pageSymbols__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .pageSymbols__container__textarea {
        width: 80%;
    }

    .pageSymbols__container__datas {
        margin-left: 0;
        padding: 30px 0;
    }
    
    .pageSymbols__container__datas .data_title {
        font-size: 46px;
    }
}