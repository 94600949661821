.socialsPage__container {
    padding: 2%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: center;
}

.socialsPage__container__block {
    position: relative;
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    margin: 10px;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}
.socialsPage__container__block.common {
    width: 250px;
    height: 100px;
    line-height: 90px;
}
.socialsPage__container__block .text {
    font-size: 2em;
    font-weight: 600;
    text-shadow: 1px 1px 0px #868686;
}

.tg {
    color: #67bbff;
}

.skp {
    color: #72c3f5;
}

.ml {
    color: #787b7c;
}

.hc {
    color: #959595;
}

.vk {
    color: #00619f;
}

.inst {
    color: #dd5aa6;
}

.fb {
    color: #006fb5;
}

.x {
    color: #4bc3e7;
}

.stm {
    color: #000000;
}

.dtf {
    color: #25a7d9;
}

.coub {
    color: #25a7d9;
}

.tw {
    color: #781c8f;
}

.yt {
    color: #d11919;
}

.thr {
    color: #000000;
}