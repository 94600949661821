code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    padding: 5px 8px;
    margin: 5px 5px;
    background-color: #dbdada;
    border-radius: 5px;
}

a {    
    color: inherit;
    text-decoration: none;
}

.mainPage__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    padding: 0 30px;
}

.mainPage__container__info-block{
    background-color: white;
    width: 15%;
    min-width: 290px;
    border-right: 1px solid #d0d7de;
    border-left: 1px solid #d0d7de;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0 30px;
    line-height: 1.3;
}

.mainPage__container__info-block div {
    padding: 7px 0 3px 0;
}

.mainPage__container__info-block__photo {
    margin-top: 23px;
}

.mainPage__container__info-block__photo img {
    width: 100%;
    border-bottom: 1px solid #d0d7de;
    border-radius: 25%;
}

.mainPage__container__info-block__name-age {
    font-size: 42px;
    font-weight: 300;
}
.mainPage__container__info-block__spec_info {
    font-size: 16px;
    color: #a5a5a5;
    font-weight: 300;
}


.mainPage__container__info-block__stack {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 12px;
    margin-left: -5px;
}

.mainPage__container__info-block__soc-containers-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.mainPage__container__info-block__soc-containers-block__soc-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 5px;
    margin: 0 0 0 -5px;
}
.mainPage__container__info-block__soc-containers-block__soc-container:hover {
    background: #000000;
    color: #fff;
    border-radius: 5px;
} 

.mainPage__container__info-block__soc-containers-block__soc-container:hover img {
    -webkit-filter: invert(100%);
    filter: invert(100%);
} 

.mainPage__container__info-block__soc-containers-block__soc-container img {
    width: 32px;
    border-radius: 16px;
}
.mainPage__container__info-block__soc-containers-block__soc-container__text {
    font-weight: 300;
    font-size: 16px;
    margin: 5px 5px 5px 10px;
}


.mainPage__container__projects-block {
    height: 100%;
    background-color: #f6f8fa;
    width: 75%;
}

.mainPage__container__projects-block__container {
    padding: 0 0 0 50px;
    margin: 20px 0 60px;
}

.mainPage__container__projects-block__container__header{
    font-size: 32px;
    padding: 0 0 20px 0;
    font-weight: 800;
    text-transform: uppercase;
}

.mainPage__container__projects-block__container__blocks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mainPage__container__projects-block__container__blocks__block {
    height: 160px;
    min-width: 360px;
    border: 1px solid #ccc;
    border-radius: 20px;
    background-color: #fff;
    margin: 0 20px 20px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    transition: 0.2s linear;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: 0 20px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 10px rgb(211 211 211);
}

.mainPage__container__projects-block__container__blocks__block:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.mainPage__container__projects-block__container__blocks__block .text_back {
    position: absolute;
    opacity: 0.1;
    position: absolute;
    opacity: 0.1;
    color: #000;
    font-size: 2.6em;
    right: -38px;
    top: -44px;
    font-weight: 400;
}

.mainPage__container__projects-block__container__blocks__block img {
    position: absolute;
    opacity: 0.1;
}

.mainPage__container__projects-block__container__blocks__block img.hammer {
    position: absolute;
    width: 90%;
    opacity: 0.1;
}

.mainPage__container__projects-block__container__blocks__block img.youtube {
    position: absolute;
    right: -68px;
    top: -132px;
    width: 70%;
    opacity: 0.3;
}

.mainPage__container__projects-block__container__blocks__block .type {
    font-size: 18px;
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: 400;
    border: 1px solid;
    border-radius: 11px;
    padding: 5px 10px; 
    background-color: #000;
    color: #fff;
}
.mainPage__container__projects-block__container__blocks__block .text {
    max-width: 300px;
}

@media screen and (max-height: 950px) {
    .mainPage__container__info-block{
        height: auto;
        padding-bottom: 10px;
    }
}

@media screen and (max-width: 1250px) {
    .mainPage__container__projects-block__container__blocks {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

@media screen and (max-width: 850px) {
    .mainPage__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;
        height: auto;
        padding: 0;
    }

    .mainPage__container__info-block {
        border-bottom: 1px solid #d0d7de;
        border-top: 1px solid #d0d7de;
        padding-bottom: 10px;
        margin-bottom: 20px;
        margin-top: 10px;
        border-radius: 10px;
    }
    .mainPage__container__projects-block {
        margin: 0;
        width: 100%;
    }    

    .mainPage__container__projects-block__container{
        padding: 0 15px;
        margin: 20px 0;
    }

    .mainPage__container__projects-block__container__blocks__block {
        min-width: inherit;
        margin: 0 0 20px 0;
    }
}
  