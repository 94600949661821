.page404__container {
    height: inherit;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.page404__container__error-text{
    font-size: 72px;
    font-weight: 600;
}
.page404__container__youtube-v{
    height: auto;
    width: auto;   
    margin: 50px 0 0px 0;
}