.pageLogic {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: start;
}

.warning {
    margin: 10px 0;
    padding: 20px;
    background-color: beige;
    font-size: 14px;
    font-weight: 100;
}

.pageLogic__check_filter {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.pageLogic__check_filter__type_btn {
    height: 60px;
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 100;
    cursor: pointer;
    border: 1px solid #ddd;
}

.pageLogic__check_filter__type_btn.selected {
    background-color: #ddd;
}

.pageLogic__check_filter__type_btn:hover {
    font-size: 28px;
}


.pageLogic__container {
    width: 40%;
    padding-top: 15px;
}

.pageLogic__container__textarea {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: start;
    min-width: 100px;
    min-height: 150px;
    margin: 0;
    padding: 0 10px;
    margin: 0 1px;
}

.pageLogic__container__textarea textarea {
    width: 100%;
    height: 150px;
    border: 1px solid #ccc;
    border-radius: 10px;
    resize: none;
    padding: 10px;
    font-size: 16px;
}

.pageLogic__container__results {

}


.pageLogic__container__results__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px 0 5px;
    font-size: 14px;
    font-weight: 200;
    justify-content: space-between;
}

.pageLogic__container__results__header__title {
    padding: 2px 0 0 0;
    font-size: 16px;
}

.pageLogic__container__results__header__filter{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.pageLogic__container__results__header__filter__button{
    border: 1px solid #ddd;
    min-width: 50px;
    padding: 1px 15px 2px;
    margin: 0 0 0 5px;
    cursor: pointer;
    text-align: center;

}

.pageLogic__container__results__header__filter__button:hover,
.pageLogic__container__results__header__filter__button.selected{
    background-color: #ddd;
}

.pageLogic__container__results__data {
    max-height: 350px;
    overflow-y: auto;
}

.pageLogic__container__results__data__nodata {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    padding: 25px 0 0 0;
    font-size: 24px;
    font-weight: 100;
    color: #ddd;
    text-align: center;
}

.pageLogic__container__results__data__grid_zone .case:hover {
    background-color: initial;
}

.pageLogic__container__results__data__grid_zone .grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.pageLogic__container__results__data__grid_zone .grid__cell {
    width: 7%;
    height: 20px;
    margin-bottom: 1px;
    background-color: #ddd;
}

.pageLogic__container__results__data__grid_zone .grid__cell.filled {
    cursor: pointer;
}

.pageLogic__container__results__data__grid_zone .grid__cell.filled.selected,
.pageLogic__container__results__data__grid_zone .grid__cell.filled:hover {
    opacity: 0.5;
}

.pageLogic__container__results__data__grid_zone .grid__cell.filled.true {
    background-color: rgb(123, 199, 123);
}

.pageLogic__container__results__data__grid_zone .grid__cell.filled.false {
    background-color: rgb(235, 116, 116);
}

.case {
    margin: 5px 0 0 0;
    font-family: Courier New;
    font-size: 14px;
    font-weight: 100;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.case:hover {
    background-color: #ddd;
}

.case__parsed_formula {
    margin: 5px 0 0 0;
    font-family: Courier New;
    font-size: 14px;
    font-weight: 100;
    padding: 0 10px 5px 0;
}

.case__result {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    padding: 0 0 2px 0;
}

.case__result.true {
    background-color: rgb(123, 199, 123);
}

.case__result.false {
    background-color: rgb(235, 116, 116);
}